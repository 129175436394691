<template>
    <v-row>
        <v-col cols="12" class="px-0 py-0" style="border-bottom:1px solid #DADCE0!important;">
            <v-btn v-if="permissao('aluno_novo')" fixed bottom right to="financeiro/receber/novo" fab color="primary"><v-icon dark>mdi-plus</v-icon></v-btn>
            <v-toolbar color="primary" dark flat height="auto">
                <v-toolbar-title class="py-2 hidden-xs-only">Filtrar</v-toolbar-title>
                <v-spacer class="hidden-xs-only"/>
                <v-row>
                    <v-col cols="12" md="6" offset-md="6"><v-text-field dense label="Buscar:" hide-details flat outlined v-model="filtro.busca" /></v-col>
                </v-row>
            </v-toolbar>
        </v-col>

        <v-col cols="12">
            <v-card>
                <v-card-text>
                    <FinanceiroReceberListar :filtro="filtro" />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import FinanceiroReceberListar from "@/Views/Financeiro/Component/FinanceiroReceberListar"
    import {mapState} from 'vuex'

    export default {
        name: "FinanceiroReceber",
        components: {FinanceiroReceberListar},
        data() {
            return {
                filtro : {}
            }
        },
        computed : {
            ...mapState(['baseUrl'])
        },
        methods : {

        }
    }
</script>

<style scoped>

</style>
