<template>
    <div>
        <v-simple-table v-if="100 > 0">
            <template v-slot:default>
                <thead>
                <tr>
                    <th>Item</th>
                    <th>Valor</th>
                    <th>Status</th>
                </tr>
                </thead>

                <tbody>
                    <tr v-for="(a, i) in [0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9]" :key="i">
                        <td :key="a.id_aluno">
                            <router-link exact color="primary" v-if="permissao('aluno_abrir')" :to="`/disciplina/gerenciar/1010`"><strong>Mensalidade</strong></router-link>
                            <strong v-else>{{a.aluno}}</strong><br/>
                            <small><strong>ID:</strong> 1010</small>
                        </td>
                        <td>R$ 350,00</td>
                        <td>Pendente</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :total="100" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'

export default {
    name: "FinanceiroReceberListar",
    props : ['filtro'],
    components: {Paginacao},
    data() {
        return {

        }
    },
    watch : {

    }
}
</script>

<style scoped>

</style>
